export const GET_PROFITABLE_REPORTS = 'GET_PROFITABLE_REPORTS';
export const GET_LIASONPROCESSOWNER = 'GET_LIASONPROCESSOWNER';
export const GET_PROJECT_NAMES = 'GET_PROJECT_NAMES';
export const GET_TASK_DELAYS = 'GET_TASK_DELAYS';
export const GET_PROFITABLE_REPORT_COUNT = 'GET_PROFITABLE_REPORT_COUNT';
export const GET_PENDING_TASK = 'GET_PENDING_TASK';
export const GET_LIAISON_PROJECT_NAMES = 'GET_LIAISON_PROJECT_NAMES';

export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
// export const OTP_SENT_SUCCESS = 'OTP_SENT_SUCCESS';
// export const OTP_VALID_SUCCESS = 'OTP_VALID_SUCCESS';
// export const OTP_VALID_FAILS = 'OTP_VALID_FAILS';

export const GET_USER_DASHBOARD_DATA = 'GET_USER_DASHBOARD_DATA';
export const GET_USER_DASHBOARD_TASK = 'GET_USER_DASHBOARD_TASK';
export const GET_CIVIL_PROCESS_DATA = 'GET_CIVIL_PROCESS_DATA';


export const GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_ZONE_DATA = "GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_ZONE_DATA";
export const GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_PROJECT_DATA = "GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_PROJECT_DATA";
export const GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_FILE_DATA = "GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_FILE_DATA";

export const GET_DASHBOARD_PLAN_APPROVAL_APPLIED_ZONE_DATA = "GET_DASHBOARD_PLAN_APPROVAL_APPLIED_ZONE_DATA";
export const GET_DASHBOARD_PLAN_APPROVAL_APPLIED_PROJECT_DATA = "GET_DASHBOARD_PLAN_APPROVAL_APPLIED_PROJECT_DATA";
export const GET_DASHBOARD_PLAN_APPROVAL_APPLIED_FILE_DATA = "GET_DASHBOARD_PLAN_APPROVAL_APPLIED_FILE_DATA";
export const SET_FUNNEL_ITEM_ID = "SET_FUNNEL_ITEM_ID";

export const GET_DASHBOARD_STOCK_ZONE_DATA = "GET_DASHBOARD_STOCK_ZONE_DATA"
export const GET_DASHBOARD_STOCK_PROJECT_DATA = "GET_DASHBOARD_STOCK_PROJECT_DATA"
export const GET_DASHBOARD_STOCK_PROJECT_SITE_DATA = "GET_DASHBOARD_STOCK_PROJECT_SITE_DATA"

export const GET_DASHBOARD_STOCK_AGING_ZONE_DATA = "GET_DASHBOARD_STOCK_AGING_ZONE_DATA"
export const GET_DASHBOARD_STOCK_AGING_PROJECT_DATA = "GET_DASHBOARD_STOCK_AGING_PROJECT_DATA"
export const GET_DASHBOARD_STOCK_AGING_FILE_DATA = "GET_DASHBOARD_STOCK_AGING_FILE_DATA"

export const GET_DASHBOARD_LAND_AQUIRED_ZONE_DATA = "GET_DASHBOARD_LAND_AQUIRED_ZONE_DATA"
export const GET_DASHBOARD_LAND_AQUIRED_PROJECT_DATA = "GET_DASHBOARD_LAND_AQUIRED_PROJECT_DATA"
export const GET_DASHBOARD_LAND_AQUIRED_FILE_DATA = "GET_DASHBOARD_LAND_AQUIRED_FILE_DATA"

export const GET_DASHBOARD_APPLAN_APPROVAL_ZONE_DATA = "GET_DASHBOARD_APPLAN_APPROVAL_ZONE_DATA"
export const GET_DASHBOARD_APPLAN_APPROVAL_PROJECT_DATA = "GET_DASHBOARD_APPLAN_APPROVAL_PROJECT_DATA"
export const GET_DASHBOARD_APPLAN_APPROVAL_FILE_DATA = "GET_DASHBOARD_APPLAN_APPROVAL_FILE_DATA"
// ________BD chart data seperate___________
export const GET_DASHBOARD_LAND_AQUIRED_FINANCIAL_YEAR_DATA = "GET_DASHBOARD_LAND_AQUIRED_FINANCIAL_YEAR_DATA"
export const GET_DASHBOARD_PLAN_APPROVAL_FINANCIAL_YEAR_DATA = "GET_DASHBOARD_PLAN_APPROVAL_FINANCIAL_YEAR_DATA"
export const GET_DASHBOARD_PLAN_APPROVED_TARGET_ACTUAL_FINANCIAL_YEAR_DATA = "GET_DASHBOARD_PLAN_APPROVED_TARGET_ACTUAL_FINANCIAL_YEAR_DATA"
export const GET_DASHBOARD_DATA_DEPARTMENT = "GET_DASHBOARD_DATA_DEPARTMENT";
export const GET_DASHBOARD_DEPARTMENT = "GET_DASHBOARD_DEPARTMENT";
export const GET_LIAISON_PROCESS_PLAN_REPORT = "GET_LIAISON_PROCESS_PLAN_REPORT";
export const GET_LIAISON_PROCESS_PLAN_REPORT_DETAIL = "GET_LIAISON_PROCESS_PLAN_REPORT_DETAIL";


export const GET_MY_DASHBOARD_DATA_FILTER_USER = "GET_MY_DASHBOARD_DATA_FILTER_USER";
export const GET_DASHBOARD_CRM_PROJECT_OUTSTANDING = "GET_DASHBOARD_CRM_PROJECT_OUTSTANDING";
export const GET_DASHBOARD_CRM_PROJECT_OUTSTANDING_EXPORT = "GET_DASHBOARD_CRM_PROJECT_OUTSTANDING_EXPORT";
export const GET_DASHBOARD_CRM_SITE_OUTSTANDING = "GET_DASHBOARD_CRM_SITE_OUTSTANDING";
export const GET_DASHBOARD_CRM_SITE_OUTSTANDING_EXPORT = "GET_DASHBOARD_CRM_SITE_OUTSTANDING_EXPORT";
export const GET_DASHBOARD_CRM_PROJECT_TARGET = "GET_DASHBOARD_CRM_PROJECT_TARGET";
export const GET_DASHBOEAR_CRM_SITE_TARGET = "GET_DASHBOEAR_CRM_SITE_TARGET";
export const GET_DASHBOARD_CRM_PROJECT_RECEIVED = "GET_DASHBOARD_CRM_PROJECT_RECEIVED";
export const GET_DASHBOARD_CRM_SITE_RECEIVED = "GET_DASHBOARD_CRM_SITE_RECEIVED";
export const GET_DASHBOARD_CRM_PROJECT_REALIZED = "GET_DASHBOARD_CRM_PROJECT_REALIZED";
export const GET_DAHSBOARD_CRM_SITE_REALIZED = "GET_DAHSBOARD_CRM_SITE_REALIZED";
export const GET_DASHBOARD_CRM_PROJECT_NOT_REALIZED = "GET_DASHBOARD_CRM_PROJECT_NOT_REALIZED";
export const GET_DAHSBOARD_CRM_SITE_NOT_REALIZED = "GET_DAHSBOARD_CRM_SITE_NOT_REALIZED";
export const GET_DASHBOARD_DATA_PRE_SALES = "GET_DASHBOARD_DATA_PRE_SALES";
export const GET_DASHBOARD_DATA_MARKETING_FUNNEL = "GET_DASHBOARD_DATA_MARKETING_FUNNEL";
export const GET_GRN_ON_TIME_REPORT = "GET_GRN_ON_TIME_REPORT";
export const GET_GRN_ON_TIME_REPORT_EXPORT = "GET_GRN_ON_TIME_REPORT_EXPORT";
export const GET_VENDOR_LEAD_TIME_REPORT = "GET_VENDOR_LEAD_TIME_REPORT";
export const GET_VENDOR_LEAD_TIME_REPORT_EXPORT = "GET_VENDOR_LEAD_TIME_REPORT_EXPORT";
export const GET_STOCK_PROJECT = "GET_STOCK_PROJECT";
export const GET_PURCHASE_INVOICE_ON_TIME_KPI = "GET_PURCHASE_INVOICE_ON_TIME_KPI";
export const GET_PURCHASE_INVOICE_ON_TIME_KPI_EXPORT = "GET_PURCHASE_INVOICE_ON_TIME_KPI_EXPORT";
export const GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT = "GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT";
export const GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT_EXPORT = "GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT_EXPORT";
export const GET_MATERIAL_QUANTITY_REJECTION_KPI = "GET_MATERIAL_QUANTITY_REJECTION_KPI";
export const GET_MATERIAL_QUANTITY_REJECTION_KPI_EXPORT = "GET_MATERIAL_QUANTITY_REJECTION_KPI_EXPORT";
export const GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT = "GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT";
export const GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT_EXPORT = "GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT_EXPORT";
export const GET_INDENT_PURCHASE_ORDER_KPI_REPORT = "GET_INDENT_PURCHASE_ORDER_KPI_REPORT";
export const GET_INDENT_PURCHASE_ORDER_KPI_REPORT_EXPORT = "GET_INDENT_PURCHASE_ORDER_KPI_REPORT_EXPORT";
export const GET_DASHBOARD_DATA_REGISTRATION_TURN_AROUND_TIME = "GET_DASHBOARD_DATA_REGISTRATION_TURN_AROUND_TIME";
export const GET_REGISTRATION_TURNAROUND_TIME_REPORT = "GET_REGISTRATION_TURNAROUND_TIME_REPORT";
export const GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_PROJECT = "GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_PROJECT";
export const GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_USER = "GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_USER";
export const GET_CIVIL_RESOURCE_REPORT = "GET_CIVIL_RESOURCE_REPORT";
export const GET_CIVIL_RESOURCE_REPORT_NON_MATERIAL = "GET_CIVIL_RESOURCE_REPORT_NON_MATERIAL";

export const GET_ON_TIME_DELIVERY_KPI = "GET_ON_TIME_DELIVERY_KPI";
export const GET_ON_TIME_DELIVERY_KPI_EXPORT = "GET_ON_TIME_DELIVERY_KPI_EXPORT";
export const GET_ON_TIME_DELIVERY_KPI_REPORT = "GET_ON_TIME_DELIVERY_KPI_REPORT";
export const GET_ON_TIME_DELIVERY_KPI_REPORT_EXPORT = "GET_ON_TIME_DELIVERY_KPI_REPORT_EXPORT";
export const GET_PROCUREMENT_KPI_REPORT = "GET_PROCUREMENT_KPI_REPORT";

export const GET_SALES_CRM_REPORT = "GET_SALES_CRM_REPORT";
export const GET_SALES_CRM_REPORT_EXPORT = "GET_SALES_CRM_REPORT_EXPORT";
export const GET_CRM_PROJECTS = "GET_CRM_PROJECTS";
export const GET_SCALE_TYPE = "GET_SCALE_TYPE";
export const GET_CRM_SITE = "GET_CRM_SITE";
export const GET_CRM_SITE_DIMENSION = "GET_CRM_SITE_DIMENSION";
export const GET_KPI_SALES_REPORT = "GET_KPI_SALES_REPORT";
export const GET_KPI_SALES_REPORT_EXPORT = "GET_KPI_SALES_REPORT_EXPORT";
export const GET_KPI_SALES_REPORT_EXECUTIVE_FILTER = "GET_KPI_SALES_REPORT_EXECUTIVE_FILTER"
export const GET_KPI_SALES_REPORT_DETAILS = "GET_KPI_SALES_REPORT_DETAILS"
export const GET_KPI_SALES_REPORT_DETAILS_PROJECT_FILTER = "GET_KPI_SALES_REPORT_DETAILS_PROJECT_FILTER"
export const GET_KPI_SALES_REPORT_DETAILS_EXPORT = "GET_KPI_SALES_REPORT_DETAILS_EXPORT"
export const GET_KPI_PRE_SALES_REPORT = "GET_KPI_PRE_SALES_REPORT"
export const GET_KPI_PRE_SALES_REPORT_EXPORT = "GET_KPI_PRE_SALES_REPORT_EXPORT"
export const GET_KPI_PRE_SALES_REPORT_EXECUTIVE_FILTER = "GET_KPI_PRE_SALES_REPORT_EXECUTIVE_FILTER"
export const GET_KPI_SALES_PRE_REPORT_DETAILS = "GET_KPI_SALES_PRE_REPORT_DETAILS"
export const GET_KPI_PRE_SALES_REPORT_DETAILS_PROJECT_FILTER = "GET_KPI_PRE_SALES_REPORT_DETAILS_PROJECT_FILTER"
export const GET_KPI_SALES_PRE_REPORT_DETAILS_EXPORT = "GET_KPI_SALES_PRE_REPORT_DETAILS_EXPORT"
export const GET_KPI_SALES_CRM_REPORT = "GET_KPI_SALES_CRM_REPORT"
export const GET_KPI_SALES_CRM_REPORT_EXPORT = "GET_KPI_SALES_CRM_REPORT_EXPORT"
export const GET_KPI_SALES_CRM_REPORT_PROJECT_FILTER = "GET_KPI_SALES_CRM_REPORT_PROJECT_FILTER"
export const GET_KPI_SALES_CRM_REPORT_SOURCE_FILTER = "GET_KPI_SALES_CRM_REPORT_SOURCE_FILTER"
export const GET_KPI_SALES_CRM_REPORT_SALES_EXECUTIVE_FILTER = "GET_KPI_SALES_CRM_REPORT_SALES_EXECUTIVE_FILTER"
export const GET_KPI_SALES_REPORT2 = "GET_KPI_SALES_REPORT2"
export const GET_KPI_SALES_REPORT2_EXPORT = "GET_KPI_SALES_REPORT2_EXPORT"
export const GET_KPI_SALES_SUMMARY_REPORT = "GET_KPI_SALES_SUMMARY_REPORT"
export const GET_KPI_SALES_SUMMARY_REPORT_EXPORT = "GET_KPI_SALES_SUMMARY_REPORT_EXPORT"
export const GET_KPI_SALES_SUMMARY_REPORT_DETAILS = "GET_KPI_SALES_SUMMARY_REPORT_DETAILS"
export const GET_KPI_SALES_SUMMARY_REPORT_DETAILS_EXPORT = "GET_KPI_SALES_SUMMARY_REPORT_DETAILS_EXPORT"
export const GET_KPI_TELECALLING_REPORT = "GET_KPI_TELECALLING_REPORT"
export const GET_KPI_TELECALLING_REPORT_EXPORT = "GET_KPI_TELECALLING_REPORT_EXPORT"

export const GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL = "GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL"
export const GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL_EXPORT = "GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL_EXPORT"
export const GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL = "GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL"
export const GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL_EXPORT = "GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL_EXPORT"

export const GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS = "GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS"
export const GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS_EXPORT = "GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS_EXPORT"
export const GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS = "GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS"
export const GET_CAMPAIGN_REPORT = "GET_CAMPAIGN_REPORT";
export const GET_CAMPAIGN_REPORT_EXPORT = "GET_CAMPAIGN_REPORT_EXPORT"
export const GET_CAMPAIGN_REPORT_FILTER_CAMPAIGN = "GET_CAMPAIGN_REPORT_FILTER_CAMPAIGN";

export const GET_KPI_SALES_REPORT_SITE_VISIT_DETAILS_EXPORT = "GET_KPI_SALES_REPORT_SITE_VISIT_DETAILS_EXPORT"
export const GET_CAMPAIGN_PROJECT_REPORT = "GET_CAMPAIGN_PROJECT_REPORT";
export const GET_CAMPAIGN_PROJECT_REPORT_EXPORT = "GET_CAMPAIGN_PROJECT_REPORT_EXPORT";
export const GET_CAMPAIGN_PROJECT_REPORT_FILTER_PROJECT = "GET_CAMPAIGN_PROJECT_REPORT_FILTER_PROJECT";
export const GET_CAMPAIGN_ENQUIRY_REPORT = "GET_CAMPAIGN_ENQUIRY_REPORT";
export const GET_CAMPAIGN_ENQUIRY_REPORT_EXPORT = "GET_CAMPAIGN_ENQUIRY_REPORT_EXPORT";
export const GET_CAMPAIGN_BOOKING_REPORT = "GET_CAMPAIGN_BOOKING_REPORT";
export const GET_CAMPAIGN_BOOKING_REPORT_EXPORT = "GET_CAMPAIGN_BOOKING_REPORT_EXPORT"
export const GET_CAMPAIGN_BOOKING_CANCELLED_REPORT = "GET_CAMPAIGN_BOOKING_CANCELLED_REPORT";
export const GET_CAMPAIGN_BOOKING_CANCELLED_REPORT_EXPORT = "GET_CAMPAIGN_BOOKING_CANCELLED_REPORT_EXPORT";
export const GET_CAMPAIGN_SITE_VISIT_REPORT = "GET_CAMPAIGN_SITE_VISIT_REPORT";
export const GET_CAMPAIGN_SITE_VISIT_REPORT_EXPORT = "GET_CAMPAIGN_SITE_VISIT_REPORT_EXPORT"
export const GET_FINANCIAL_YEAR = "GET_FINANCIAL_YEAR";
export const GET_FINANCIAL_YEAR_SCALE_TYPE = "GET_FINANCIAL_YEAR_SCALE_TYPE";
export const GET_FINANCIAL_YEAR_SCALE_VALUE = "GET_FINANCIAL_YEAR_SCALE_VALUE";
export const GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS_EXPORT = "GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS_EXPORT"

export const GET_PRE_SALES_SITE_VISIT = "GET_PRE_SALES_SITE_VISIT"
export const GET_PRE_SALES_SITE_VISIT_EXPORT = "GET_PRE_SALES_SITE_VISIT_EXPORT"
export const GET_KPITELECALLING_FILTER_USER = "GET_KPITELECALLING_FILTER_USER"; 
export const GET_KPI_SALES_SUMMARY_FILTER_USER = "GET_KPI_SALES_SUMMARY_FILTER_USER"
export const GET_SALES_REPORT="GET_SALES_REPORT";
export const GET_SALES_REPORT_EXPORT="GET_SALES_REPORT_EXPORT";
export const GET_SALES_REPORT_SITE_VISIT="GET_SALES_REPORT_SITE_VISIT";
export const GET_SALES_REPORT_SITE_VISIT_EXPORT="GET_SALES_REPORT_SITE_VISIT_EXPORT";
export const GET_SALES_REPORT_FOLLOW_UP="GET_SALES_REPORT_FOLLOW_UP";
export const GET_SALES_REPORT_FOLLOW_UP_EXPORT="GET_SALES_REPORT_FOLLOW_UP_EXPORT";
export const GET_SALES_REPORT_PROSPECTIVE_CLIENT="GET_SALES_REPORT_PROSPECTIVE_CLIENT";
export const GET_SALES_REPORT_PROSPECTIVE_CLIENT_EXPORT="GET_SALES_REPORT_PROSPECTIVE_CLIENT_EXPORT";
export const GET_SALES_REPORT_BOOKING="GET_SALES_REPORT_BOOKING";
export const GET_SALES_REPORT_BOOKING_EXPORT="GET_SALES_REPORT_BOOKING_EXPORT";
export const GET_SALES_REPORT_CANCELLATION="GET_SALES_REPORT_CANCELLATION"
export const GET_SALES_REPORT_CANCELLATION_EXPORT="GET_SALES_REPORT_CANCELLATION_EXPORT"
export const GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT="GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT";
export const GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT_EXPORT="GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT_EXPORT"
export const GET_MONTH_WEEK="GET_MONTH_WEEK";
export const GET_SALES_REPORT_PLANNED_CP_MEETING="GET_SALES_REPORT_PLANNED_CP_MEETING";
export const GET_SALES_REPORT_PLANNED_CP_MEETING_EXPORT="GET_SALES_REPORT_PLANNED_CP_MEETING_EXPORT";
export const GET_SALES_REPORT_CLIENT_MEETING="GET_SALES_REPORT_CLIENT_MEETING";
export const GET_SALES_REPORT_CLIENT_MEETING_EXPORT="GET_SALES_REPORT_CLIENT_MEETING_EXPORT";
export const GET_SALES_REPORT_TEAM="GET_SALES_REPORT_TEAM";
export const GET_SALES_REPORT_TEAM_EXPORT="GET_SALES_REPORT_TEAM_EXPORT";
export const GET_SALES_REPORT_FILTER_TEAM="GET_SALES_REPORT_FILTER_TEAM";
export const GET_SALES_REPORT_FILTER_USER="GET_SALES_REPORT_FILTER_USER";
export const GET_SALES_REPORT_PLANNED_FOLLOW_UP="GET_SALES_REPORT_PLANNED_FOLLOW_UP";
export const GET_SALES_REPORT_PLANNED_FOLLOW_UP_EXPORT="GET_SALES_REPORT_PLANNED_FOLLOW_UP_EXPORT";
export const GET_SALES_REPORT_PLANNED_SITE_VISIT ="GET_SALES_REPORT_PLANNED_SITE_VISIT";
export const GET_SALES_REPORT_PLANNED_SITE_VISIT_EXPORT ="GET_SALES_REPORT_PLANNED_SITE_VISIT_EXPORT";
export const GET_KPI_SALES_REPORT_CRM_BOOKING_DETAIL_FILTER_PROJECT = "GET_KPI_SALES_REPORT_CRM_BOOKING_DETAIL_FILTER_PROJECT";
export const GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAIL_FILTER_PROJECT = "GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAIL_FILTER_PROJECT"
export const GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL_FILTER_PROJECT="GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL_FILTER_PROJECT";
export const GET_SALES_REPORT_COMPLETED_CP_MEETING="GET_SALES_REPORT_COMPLETED_CP_MEETING";
export const GET_SALES_REPORT_COMPLETED_CP_MEETING_EXPORT="GET_SALES_REPORT_COMPLETED_CP_MEETING_EXPORT";
export const GET_SALES_REPORT_PLANNED_CLIENT_MEETING="GET_SALES_REPORT_PLANNED_CLIENT_MEETING";
export const GET_SALES_REPORT_PLANNED_CLIENT_MEETING_EXPORT="GET_SALES_REPORT_PLANNED_CLIENT_MEETING_EXPORT";
export const GET_SALES_REPORT_COMPLETED_CLIENT_MEETING="GET_SALES_REPORT_COMPLETED_CLIENT_MEETING";
export const GET_SALES_REPORT_COMPLETED_CLIENT_MEETING_EXPORT="GET_SALES_REPORT_COMPLETED_CLIENT_MEETING_EXPORT";
export const GET_SALES_REPORT_ENQUIRY_FOLLOW_UP="GET_SALES_REPORT_ENQUIRY_FOLLOW_UP";
export const GET_SALES_REPORT_ENQUIRY_FOLLOW_UP_EXPORT="GET_SALES_REPORT_ENQUIRY_FOLLOW_UP_EXPORT";
//cp sales Report
export const GET_SALES_CHANNEL_PARTNER_REPORT="GET_SALES_CHANNEL_PARTNER_REPORT";
export const GET_SALES_CHANNEL_PARTNER_REPORT_EXPORT ="GET_SALES_CHANNEL_PARTNER_REPORT_EXPORT"
export const GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT="GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT";
export const GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT_EXPORT="GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT_EXPORT"
export const GET_SALES_CHANNEL_PARTNER_ENQUIRY_REPORT="GET_SALES_CHANNEL_PARTNER_ENQUIRY_REPORT";
export const GET_SALES_CHANNEL_PARTNER_ENQUIRY_REPORT_EXPORT="GET_SALES_CHANNEL_PARTNER_ENQUIRY_REPORT_EXPORT";
export const GET_SALES_CHANNEL_PARTNER_SITE_VISIT_REPORT="GET_SALES_CHANNEL_PARTNER_SITE_VISIT_REPORT";
export const GET_SALES_CHANNEL_PARTNER_SITE_VISIT_REPORT_EXPORT="GET_SALES_CHANNEL_PARTNER_SITE_VISIT_REPORT_EXPORT"
export const GET_SALES_CHANNEL_PARTNER_BOOKING_REPORT="GET_SALES_CHANNEL_PARTNER_BOOKING_REPORT";
export const GET_SALES_CHANNEL_PARTNER_BOOKING_REPORT_EXPORT="GET_SALES_CHANNEL_PARTNER_BOOKING_REPORT_EXPORT"
export const GET_SALES_CHANNEL_PARTNER_CANCELLATION_REPORT="GET_SALES_CHANNEL_PARTNER_CANCELLATION_REPORT";
export const GET_SALES_CHANNEL_PARTNER_CANCELLATION_REPORT_EXPORT="GET_SALES_CHANNEL_PARTNER_CANCELLATION_REPORT_EXPORT"
export const GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT_FILTER_PROJECT="GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT_FILTER_PROJECT";
export const GET_SALES_CHANNEL_PARTNER_ENQUIRY_REPORT_FILTER_PROJECT="GET_SALES_CHANNEL_PARTNER_ENQUIRY_REPORT_FILTER_PROJECT";
export const GET_SALES_CHANNEL_PARTNER_SITE_VISIT_REPORT_FILTER_PROJECT="GET_SALES_CHANNEL_PARTNER_SITE_VISIT_REPORT_FILTER_PROJECT";
export const GET_SALES_CHANNEL_PARTNER_BOOKING_REPORT_FILTER_PROJECT="GET_SALES_CHANNEL_PARTNER_BOOKING_REPORT_FILTER_PROJECT";
export const GET_SALES_CHANNEL_PARTNER_CANCELLATION_REPORT_FILTER_PROJECT="GET_SALES_CHANNEL_PARTNER_CANCELLATION_REPORT_FILTER_PROJECT";
//source sales Report
export const GET_SALES_SOURCE_REPORT="GET_SALES_SOURCE_REPORT";
export const GET_SALES_SOURCE_REPORT_EXPORT ="GET_SALES_SOURCE_REPORT_EXPORT"
export const GET_SALES_SOURCE_PROJECT_REPORT="GET_SALES_SOURCE_PROJECT_REPORT";
export const GET_SALES_SOURCE_PROJECT_REPORT_EXPORT="GET_SALES_SOURCE_PROJECT_REPORT_EXPORT"
export const GET_SALES_SOURCE_ENQUIRY_REPORT="GET_SALES_SOURCE_ENQUIRY_REPORT";
export const GET_SALES_SOURCE_ENQUIRY_REPORT_EXPORT="GET_SALES_SOURCE_ENQUIRY_REPORT_EXPORT";
export const GET_SALES_SOURCE_SITE_VISIT_REPORT="GET_SALES_SOURCE_SITE_VISIT_REPORT";
export const GET_SALES_SOURCE_SITE_VISIT_REPORT_EXPORT="GET_SALES_SOURCE_SITE_VISIT_REPORT_EXPORT"
export const GET_SALES_SOURCE_BOOKING_REPORT="GET_SALES_SOURCE_BOOKING_REPORT";
export const GET_SALES_SOURCE_BOOKING_REPORT_EXPORT="GET_SALES_SOURCE_BOOKING_REPORT_EXPORT"
export const GET_SALES_SOURCE_CANCELLATION_REPORT="GET_SALES_SOURCE_CANCELLATION_REPORT";
export const GET_SALES_SOURCE_CANCELLATION_REPORT_EXPORT="GET_SALES_SOURCE_CANCELLATION_REPORT_EXPORT"
export const GET_SALES_SOURCE_PROJECT_REPORT_FILTER_PROJECT="GET_SALES_SOURCE_PROJECT_REPORT_FILTER_PROJECT";
export const GET_SALES_SOURCE_ENQUIRY_REPORT_FILTER_PROJECT="GET_SALES_SOURCE_ENQUIRY_REPORT_FILTER_PROJECT";
export const GET_SALES_SOURCE_SITE_VISIT_REPORT_FILTER_PROJECT="GET_SALES_SOURCE_SITE_VISIT_REPORT_FILTER_PROJECT";
export const GET_SALES_SOURCE_BOOKING_REPORT_FILTER_PROJECT="GET_SALES_SOURCE_BOOKING_REPORT_FILTER_PROJECT";
export const GET_SALES_SOURCE_CANCELLATION_REPORT_FILTER_PROJECT="GET_SALES_SOURCE_CANCELLATION_REPORT_FILTER_PROJECT";
export const GET_ENQUIRY_SOURCE_CATEGORY="GET_ENQUIRY_SOURCE_CATEGORY";
export const GET_ENQUIRY_SOURCE_FILTER="GET_ENQUIRY_SOURCE_FILTER";
export const GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT_FILTER_CHANNEL_PARTNER="GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT_FILTER_CHANNEL_PARTNER";
export const GET_SALES_CHANNEL_PARTNER_ON_BOARD_REPORT="GET_SALES_CHANNEL_PARTNER_ON_BOARD_REPORT";
export const GET_SALES_CHANNEL_PARTNER_ON_BOARD_REPORT_EXPORT="GET_SALES_CHANNEL_PARTNER_ON_BOARD_REPORT_EXPORT";
export const GET_SALES_CHANNEL_PARTNER_REPORT_FILTER_SALES_EXECUTIVE="GET_SALES_CHANNEL_PARTNER_REPORT_FILTER_SALES_EXECUTIVE";
export const GET_PRE_SALES_REPORT="GET_PRE_SALES_REPORT"

export const GET_PRE_SALES_REPORT_FILTER_EXECUTIVE="GET_PRE_SALES_REPORT_FILTER_EXECUTIVE";
export const GET_PRE_SALES_REPORT_FILTER_SOURCE_CATEGORY="GET_PRE_SALES_REPORT_FILTER_SOURCE_CATEGORY";
export const GET_PRE_SALES_REPORT_FILTER_SOURCE="GET_PRE_SALES_REPORT_FILTER_SOURCE"
export const GET_PRE_SALES_REPORT_FILTER_PROJECT="GET_PRE_SALES_REPORT_FILTER_PROJECT";
export const GET_PRE_SALES_REPORT_EXPORT="GET_PRE_SALES_REPORT_EXPORT";
export const GET_PRE_SALES_ENQUIRY_REPORT="GET_PRE_SALES_ENQUIRY_REPORT";
export const GET_PRE_SALES_PROJECT_REPORT="GET_PRE_SALES_PROJECT_REPORT"
export const GET_PRE_SALES_PROJECT_REPORT_EXPORT="GET_PRE_SALES_PROJECT_REPORT_EXPORT";
export const GET_PRE_SALES_ENQUIRY_REPORT_EXPORT="GET_PRE_SALES_ENQUIRY_REPORT_EXPORT";
export const GET_PRE_SALES_ENQUIRY_OVER_DUE_REPORT="GET_PRE_SALES_ENQUIRY_OVER_DUE_REPORT"
export const GET_PRE_SALES_ENQUIRY_OVER_DUE_REPORT_EXPORT="GET_PRE_SALES_ENQUIRY_OVER_DUE_REPORT_EXPORT"